import React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Aside } from "components/blog/aside"
import { Breadcrumb } from "components/breadcrumb"
import { DefaultHeader } from "components/headers/default-header"

const CanIChangeTheOdds = ({ location }) => {
  const title = "How to change the odds of winning during Instant Win"
  const list = [
    { title: "Frequently Asked Questions", link: "/qa" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader lang="en-US" />
      <Layout lang="en-US">
        <Seo
          pagetitle={`FAQ | ${title}`}
          pagedesc="PARKLoT's campaigns allow you to change your odds of winning even while the campaign is running. You can increase or decrease the odds of winning depending on the status of the campaign. Prizes can be distributed to participants without any excess."
          pagepath={location.pathname}
        />
        <Breadcrumb title="Frequently Asked Questions" items={list} />
        <div className="qa-page l-content l-container">
          <main className="l-main-content">
            <h1 className="qa-page__title">
              Is it possible to change the odds of winning during the campaign?
            </h1>
            <p className="qa-page__answer">
              Yes, the probability of winning can be changed during the
              campaign.
              <br />
              However, the gift cannot be changed during the course of the
              campaign.
            </p>
            <p className="qa-page__text">
              Winners are drawn at random according to probability, so there may
              be a bias in favor of the winners. In such cases, the probability
              can be changed from the administration screen.
            </p>
            <p className="qa-page__text">
              To avoid excess gifts, make sure that all products are distributed
              to all participants to ensure the success of the campaign.
            </p>

            <div className="qa-page__detail-container">
              <div>
                <h2 className="qa-page__h2">
                  How to change the probability of winning
                </h2>
                <p className="qa-page__text">
                  The probability of winning can be set differently for each
                  gift in the campaign management screen.
                </p>
              </div>
            </div>
          </main>
          <Aside />
        </div>
      </Layout>
    </>
  )
}

export default CanIChangeTheOdds
